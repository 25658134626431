export default {
  init(vue) {
    return {
      getIP() {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/common/ip'
        })
      },
      getDict(params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/common/dict',
          params: params
        })
      },
      queryDicts(params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/common/manage/dicts',
          params: params
        })
      },
      updateDictDataObject(id, data) {
        return vue.$http({
          method: 'PUT',
          url: '/api/v1/common/manage/dict/data/' + id,
          data: data
        })
      }
    }
  }
}
