<template>
    <div class='goodsOrders'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <el-input style="width:200px;" v-model="paginationInfo.condition.mobile" maxlength="11"
                        placeholder="请输入手机号" clearable></el-input>
                    <el-date-picker v-model="create_date[0]" align="right" type="date" placeholder="选择开始时间" :picker-options="{
                        disabledDate(time) {
                            return time.getTime() > Date.now();
                        },
                    }">
                    </el-date-picker>
                    <el-date-picker v-model="create_date[1]" align="right" type="date" placeholder="选择结束时间" :picker-options="{
                        disabledDate(time) {
                            return time.getTime() > Date.now();
                        },
                    }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.status" placeholder="请选择订单状态" @change="queryList"
                        filterable clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="创建" :value="0"></el-option>
                        <el-option label="买家取消" :value="-1"></el-option>
                        <el-option label="卖家取消" :value="-2"></el-option>
                        <el-option label="系统取消" :value="-3"></el-option>
                        <el-option label="失败" :value="-4"></el-option>
                        <el-option label="已支付" :value="1"></el-option>
                        <el-option label="已发货" :value="2"></el-option>
                        <el-option label="交易完成" :value="3"></el-option>
                        <el-option label="已评价" :value="4"></el-option>
                    </el-select>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="out_order_no" label="订单号" align='center' width="150">
                </el-table-column>
                <el-table-column label="创建时间" align='center' width="300">
                    <template slot-scope="scope">{{ $moment(scope.row.create_date).format('YYYY-MM-DD HH:mm:ss')
                    }}</template>
                </el-table-column>
                <el-table-column prop="express.receive_name" label="名称" align='center'>
                </el-table-column>
                <el-table-column prop="express.phone" label="手机号" align='center'>
                </el-table-column>
                <el-table-column label="地址" align='center' width="300">
                    <template slot-scope="scope">
                        {{ scope.row.express.province }}{{ scope.row.express.city }}{{ scope.row.express.area }}{{
                            scope.row.express.street }}
                    </template>
                </el-table-column>
                <el-table-column label="商品信息" align='center' width="300">
                    <template slot-scope="scope">
                        <div>
                            规格： {{ scope.row.goods_info.sku_name || scope.row.goods_info.sku }}
                        </div>
                        <div v-if="scope.row.goods_info.size">
                            大小： {{ scope.row.goods_info.size }}
                        </div>
                        <div>
                            价格： {{ scope.row.goods_info.total_amount / 100 }} 元
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="订单状态" align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">创建</span>
                        <span v-else-if="scope.row.status == -1">买家取消</span>
                        <span v-else-if="scope.row.status == -2">卖家取消</span>
                        <span v-else-if="scope.row.status == -3">系统取消</span>
                        <span v-else-if="scope.row.status == -4">失败</span>
                        <span v-else-if="scope.row.status == 1">已支付</span>
                        <span v-else-if="scope.row.status == 2">已发货</span>
                        <span v-else-if="scope.row.status == 3">交易完成</span>
                        <span v-else-if="scope.row.status == 4">已评价</span>
                    </template>
                </el-table-column>
                <el-table-column label="支付渠道" align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.pay_channel == 'WECHATPAY'">微信</span>
                        <span v-if="scope.row.pay_channel == 'ALIPAY'">支付宝</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align='center'>
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" :disabled="scope.row.status != 1"
                            @click="showForm(scope.row._id)">修改订单状态</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="paginationInfo.pageSize"
                :total="paginationInfo.total" layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <el-dialog title="修改订单状态" :visible.sync="showFormType" width="30%" :before-close="handleClose">
                <el-select v-model="formData.status" placeholder="请选择要修改的状态">
                    <el-option label="买家取消" :value="-1"></el-option>
                    <el-option label="卖家取消" :value="-2"></el-option>
                    <el-option label="已发货" :value="2"></el-option>
                    <el-option label="交易完成" :value="3"></el-option>
                </el-select>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showFormType = false">取 消</el-button>
                    <el-button type="primary" @click="handleOrderStatus()">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            formData: {
                id: '',
                status: '',
            },
            showFormType: false,
            create_date: [],
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    create_date: null,
                    mobile: '',
                    status: '',
                }
            },
            tableData: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getManagerOrders()
    },
    mounted() {
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(() => {
                    done();
                })
                .catch(() => { });
        },
        showForm(id) {
            this.formData.id = id;
            this.formData.status = '';
            this.showFormType = true;
        },
        handleOrderStatus() {
            // 0: '创建', '-1': '买家取消', ' -2': '卖家取消', '-3': '系统取消', '-4': '失败', 1: '已支付', 2: '已发货', 3: '交易完成', 4: '已评价'
            this.$service.OnlineOrderService
                .handleOrderStatus(this.formData)
                .then(res => {
                    if (res.data.code === 0) {
                        this.getManagerOrders();
                        this.$message({
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.showFormType = false;
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                    window.console.log(err)
                    this.$message({
                        message: '网络错误，请重新修改！',
                        type: 'error'
                    });
                })
        },
        queryList() {
            if (this.create_date.length >= 2 && this.create_date[0] && this.create_date[1]) {
                this.paginationInfo.condition.create_date = [this.create_date[0], this.create_date[1]];
            } else {
                this.paginationInfo.condition.create_date = null;
            }
            this.paginationInfo.pageNo = 1;
            this.getManagerOrders()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getManagerOrders();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getManagerOrders();
        },
        getManagerOrders() {
            this.$service.OnlineOrderService
                .getManagerOrders(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data
                        this.paginationInfo.total = dataInfo.total;
                        this.tableData = dataInfo.orders;
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
<style lang='less' scoped>
.goodsOrders {}
</style>