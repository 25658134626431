<template lang="pug">
  .paidgood-form
    el-form(:model="form", label-width="100px")
      el-form-item(label="渠道")
        el-select.mid-width(v-model="form.channel")
          el-option(label="微信小程序", value="WX_MINIAPP")
          el-option(label="H5收单", value="EASY_ORDER")
      el-form-item(label="商品名称")
        el-input.mid-width(v-model="form.name")
      el-form-item(label="商品图片")
        el-carousel.cover-carousels(height="375px")
          el-carousel-item(v-for="item in form.cover_images" :key="item")
            el-image(:src="$STAITC_HOST + item", fit="fill")
        .thumbs
          .thumb(v-for="(item, index) in form.cover_images", :key="item")
            .remove-button(@click="removeCoverImage(item, index)")
              i.el-icon-close
            el-image.thumb-image(:src="$STAITC_HOST + item")
          el-upload#coverImageUpload(
            :action="uploadUrl",
            :headers="uploadHeader",
            :show-file-list="false",
            accept="image/png, image/jpeg, image/gif",
            :on-success="coverUploadSuccess",
            :on-error="coverUploadError",
            :before-upload="coverBeforeUpload"
          )
            el-button.btn-thumb(icon="el-icon-plus")
      el-form-item(label="规格")
        .skus(v-for="(item, index) in form.sku", :key="item.name")
          span.sku-infos
            el-tag {{`规格${item.name}`}}
            el-tag {{`原价¥${item.price}`}}
            el-tag(type="danger") {{`销售价¥${item.sale_price}`}}
            el-tag(type="warning") {{`返陀螺币${item.reward_coin}`}}
          span
            el-button(v-if="item.status === 0", type="warning", @click="item.status = 1") 上架
            el-button(v-else, type="primary", @click="item.status = 0") 下架
            el-button(type="danger", v-if="item.new", @click="removeSku(item, index)") 删除
        el-row(:gutter="12")
          el-col(:span="5")
            el-input(v-model="skuForm.name", placeholder="规格名")
          el-col(:span="4")
            el-tooltip(content="此规格原价", placement="top")
              el-input-number(v-model="skuForm.price", placeholder="原价", :precision="2", :step="10")
          el-col(:span="4")
            el-tooltip(content="此规格销售价格", placement="top")
              el-input-number(v-model="skuForm.sale_price", placeholder="销售价", :precision="2", :step="10")
          el-col(:span="4")
            el-tooltip(content="此规格返陀螺币", placement="top")
              el-input-number(v-model="skuForm.reward_coin", placeholder="返陀螺币", :precision="2", :step="10")
          el-col(:span="3")
            el-button(:loading="coverUploading", icon="el-icon-plus", circle, @click="addSku")
      el-form-item(label="虚拟销量")
        el-input-number(v-model="form.v_sales_count", :step="10")
      el-form-item
        div(v-loading="uploadingImage")
          el-upload#imageUpload(
            :action="uploadUrl",
            :headers="uploadHeader",
            :show-file-list="false",
            accept="image/png, image/jpeg, image/gif",
            :on-success="uploadSuccess",
            :on-error="uploadError",
            :before-upload="beforeUpload"
          )
          quill-editor.mobile-editor(v-model="form.desc", ref="descEditor", :options="descEditorOpts")
            #toolbar(slot="toolbar")
              span.ql-formats
                select.ql-font
                select.ql-size
              span.ql-formats
                button.ql-bold
                button.ql-italic
                button.ql-underline
                button.ql-strike
              span.ql-formats
                button.ql-script(value="sub")
                button.ql-script(value="super")
              span.ql-formats
                button.ql-link
                button.ql-image
                button.ql-video
              span.ql-formats
                button.ql-list(value="ordered")
                button.ql-list(value="bullet")
              span.ql-formats
                select.ql-color
                select.ql-background
              span.ql-formats
                select.ql-align
      el-form-item
        el-button(@click="$router.go(-1)") 取消
        el-button(type="primary", :loading="loading", @click="saveForm") 确定
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

// const toolbarOptions = [
//   ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//   ['blockquote', 'code-block'],

//   [{'header': 1}, {'header': 2}],               // custom button values
//   [{'list': 'ordered'}, {'list': 'bullet'}],
//   [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
//   [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
//   [{'direction': 'rtl'}],                         // text direction

//   [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
//   [{'header': [1, 2, 3, 4, 5, 6, false]}],

//   [{'color': []}, {'background': []}],          // dropdown with defaults from theme
//   [{'font': []}],
//   [{'align': []}],
//   ['link', 'image', 'video'],
//   ['clean']                                         // remove formatting button
// ]

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      loading: false,
      uploadingImage: false,
      coverUploading: false,
      form: {
        cover_images: [],
        status: 0,
        sku: [],
        channel: 'WX_MINIAPP'
      },
      skuForm: {
        name: '',
        price: 0.00,
        sale_price: 0.00,
        reward_coin: 0.00,
        status: 0,
        new: true
      },
      uploadHeader: {},
      uploadUrl: '/api/v1/upload?t=paidgood',
      descEditorOpts: {
        placeholder: '请输入商品描述',
        // theme: 'bubble',
        theme: 'snow',
        modules: {
          toolbar: {
            container: '#toolbar',  // 工具栏
            handlers: {
              'image': (value) => {
                  if (value) {
                    this.currentUpload = 'content'
                    document.querySelector('#imageUpload input').click()
                  } else {
                    this.quill.format('image', false);
                  }
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getPaidgood(this.$route.query.id)
    } 
  },
  methods: {
    getPaidgood(id) {
      this.$service.PaidGoodService
        .getById(id)
        .then(res => {
          if (res.data.code === 0) {
            this.form = res.data.data
          }
        })
    },
    // 上传图片前
    beforeUpload() {
      this.uploadingImage = true
    },
    // 上传图片成功
    uploadSuccess(res) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      // 如果上传成功
      if (res.code === 0 && res.data.path) {
        let _quill = this.$refs.descEditor.quill
        // 获取光标所在位置
        let length = this.$refs.descEditor.quill.getSelection().index
        // 插入图片  res.info为服务器返回的图片地址
        _quill.insertEmbed(length, 'image', this.$STAITC_HOST + res.data.path + '!414_jpeg_zoom')
        // 调整光标到最后
        _quill.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.uploadingImage = false
    },
    // 上传图片失败
    uploadError() {
      this.uploadingImage = false
      this.$message.error('图片插入失败')
    },
    coverUploadSuccess(res) {
      if (res.code === 0 && res.data.path) {
          this.form.cover_images.push(res.data.path)
      } else {
          this.$message.error('上传失败')
      }
      this.coverUploading = false
    },
    coverUploadError() {
      this.coverUploading = false
      this.$message.error('上传失败')
    },
    coverBeforeUpload() {
      this.coverUploading = true
    },
    saveForm() {
      this.$service.PaidGoodService
        .save(this.form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '商品信息已保存',
              type: 'success'
            });
            this.$router.push({
              name: 'paidgoods'
            })
          } else {
            this.$message({
              message: '保存过程中出现错误，message:' + res.data.message,
              type: 'error'
            });
          }
        })
    },
    addSku() {
      this.form.sku.push(Object.assign({}, this.skuForm))
      this.skuForm = {
        name: '',
        price: 0.00,
        sale_price: 0.00,
        reward_coin: 0.00,
        status: 0,
        new: true
      }
    },
    removeSku(sku, index) {
      if (sku.new) {
        this.form.sku.splice(index, 1)
      }
    },
    removeCoverImage(coverImage, index) {
      this.form.cover_images.splice(index, 1);
    }
  }
}
</script>

<style lang="less" scoped>
.paidgood-form{
  max-width: 900px;
}
.mid-width{
  width: 500px;
}
.cover-carousels{
  width: 375px;
  zoom: .8;
}
.mobile-editor{
  width: 375px;
  height: 690px;
  padding-bottom: 66px;
  border: 6px solid #333;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-radius: 12px;
  zoom: 0.8;
  line-height: normal;
}
#imageUpload{
  height: 0px;
  overflow: hidden;
}
.thumbs{
  margin-top: 12px;
  .thumb{
    display:inline-block;
    position: relative;
    margin-right: 12px;
    border: 1px solid #a3a3a3;
    width: 36px;
    height: 36px;
    cursor: pointer;
    vertical-align: text-bottom;
    .thumb-image{
      width: inherit;
      height: inherit;
    }
    .remove-button{
      position: absolute;
      right: -7px;
      top: -16px;
      z-index: 1;
      color: Red;
    }
  }
  .btn-thumb{
    vertical-align: text-bottom;
    width: 38px;
    height: 38px;
    cursor: pointer;
    border: 1px solid #a3a3a3;
    padding: 0;
    margin: 0;
  }
}
#coverImageUpload{
  display: inline-block;
}
.skus{
  margin-bottom: 6px;
  margin-left: 12px;
}
.sku-infos{
  width: 500px;
  display: inline-block;
}
</style>