<template>
    <div class='members'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <el-input style="width:200px;" v-model="paginationInfo.condition.search_key"
                        placeholder="请输入手机号/商户单号" clearable>
                    </el-input>
                    <el-input style="width:240px;" v-model="paginationInfo.condition.trade_no"
                        placeholder="请输入交易流水号（支付宝/微信）" clearable>
                    </el-input>
                    <el-date-picker v-model="create_date[0]" align="right" type="date" placeholder="选择创建开始时间"
                        :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-date-picker v-model="create_date[1]" align="right" type="date" placeholder="选择创建结束时间"
                        :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-date-picker v-model="create_date1[0]" align="right" type="date" placeholder="选择领取开始时间"
                        :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-date-picker v-model="create_date1[1]" align="right" type="date" placeholder="选择领取结束时间"
                        :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.equity_log_status" placeholder="请选择订单状态"
                        @change="queryList" filterable clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未发放" value="REQUEST"></el-option>
                        <el-option label="已发放" value="COMPLETE"></el-option>
                        <el-option label="发放失败" value="FAILED"></el-option>
                        <el-option label="失败-已退款" value="FAILED_REFUND"></el-option>
                        <el-option label="正在发放" value="PROCESS"></el-option>
                    </el-select>
                    <el-select v-model="paginationInfo.condition.status" placeholder="请选择订单状态" @change="queryList"
                        filterable clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未激活" value="PRE"></el-option>
                        <el-option label="已激活" value="ACTIVE"></el-option>
                        <el-option label="已删除" value="DELETE"></el-option>
                    </el-select>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%" border>
                <el-table-column prop="mobile" label="会员号" align='center' width="150">
                </el-table-column>
                <el-table-column label="创建时间" align='center' width="200">
                    <template slot-scope="scope">{{ $moment(scope.row.create_date).format('YYYY-MM-DD HH:mm:ss')
                        }}</template>
                </el-table-column>
                <el-table-column label="会员订购明细" align='center'>
                    <template slot-scope="scope">
                        <el-table :data="scope.row.members" style="width: 100%">
                            <el-table-column prop="out_trade_no" label="商户订单号/权益单号" align='center' width="120">
                            </el-table-column>
                            <el-table-column label="权益名称" align='center' width="200">
                                <template slot-scope="scopec">
                                    {{ judgeMemberName(scopec.row.member_type, scopec.row.sub_member_type) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="支付金额 / 方式 / 支付时间 / 支付状态" align='center'>
                                <template slot-scope="scopec">
                                    <div v-if="scopec.row.pay_logs.length">
                                        {{ scopec.row.pay_logs[0].total_amount / 100 }}元 /
                                        <span v-if="scopec.row.pay_logs[0].pay_channel == 'WECHATPAY'">微信</span>
                                        <span v-else-if="scopec.row.pay_logs[0].pay_channel == 'ALIPAY'">支付宝</span>
                                        /
                                        {{ scopec.row.pay_logs[0].success_time ?
                                            ($moment(scopec.row.pay_logs[0].success_time).format(`YYYY-MM-DD
                                        HH:mm:ss`)) : ($moment(scopec.row.pay_logs[0].update_date).format(`YYYY-MM-DD
                                        HH:mm:ss`)) }}
                                        <span v-if="scopec.row.pay_logs[0].trade_status == 'SUCCESS'">支付成功</span>
                                        <span v-else-if="scopec.row.pay_logs[0].trade_status == 'REFUND'">转入退款</span>
                                        <span v-else-if="scopec.row.pay_logs[0].trade_status == 'NOTPAY'">未支付</span>
                                        <span v-else-if="scopec.row.pay_logs[0].trade_status == 'CLOSED'">已关闭</span>
                                        <span
                                            v-else-if="scopec.row.pay_logs[0].trade_status == 'REVOKED'">已撤销（仅付款码支付会返回）</span>
                                        <span
                                            v-else-if="scopec.row.pay_logs[0].trade_status == 'USERPAYING'">用户支付中（仅付款码支付会返回）</span>
                                        <span
                                            v-else-if="scopec.row.pay_logs[0].trade_status == 'PAYERROR'">支付失败（仅付款码支付会返回）</span>
                                    </div>
                                    <div v-else>
                                        暂无支付数据。。。
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="领取权益名称 / 领取时间 / 发放状态 / 订单号" align='center'>
                                <template slot-scope="scopec">
                                    <div v-if="scopec.row.equity_logs.length">
                                        <div v-for="item in scopec.row.equity_logs" :key="item._id">
                                            {{ item.equity_name }} /
                                            {{ $moment(item.create_date).format('YYYY-MM-DD HH:mm:ss') }} /
                                            <span v-if="item.status == 'REQUEST'" style="color: red;">未发放 </span>
                                            <span v-else-if="item.status == 'COMPLETE'">已发放 </span>
                                            <span v-else-if="item.status == 'PROCESS'">发放中 </span>
                                            <span v-else-if="item.status == 'FAILED'">发放失败 </span>
                                            <span v-else-if="item.status == 'FAILED_REFUND'">失败-已退款</span>
                                            <span v-else-if="item.status == 'PROCESS'">正在发放</span>
                                            / {{ item.order_no }}
                                            <el-button v-if="item.status == 'FAILED'" slot="reference" type="success"
                                                size="mini" style="margin-left: 15px;" plain
                                                @click="handleManagerRetryRecharge(scope.row._id, item._id)">重试</el-button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        暂无领取数据。。。
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            create_date: [new Date(), new Date()],
            create_date1: [],
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    trade_no: "",
                    mobile: '',
                    create_date: '',
                    log_create_date: '',
                    equity_log_status: '',
                    status: 'ACTIVE'
                }
            },
            EquityMemberList: [],
            tableData: [],
            formData: {
            },
        }
    },
    computed: {},
    watch: {},
    created() {
        this.paginationInfo.condition.create_date = [this.create_date[0], this.create_date[1]];
        this.getMembers()
        this.getEquityMember()
    },
    mounted() {
    },
    methods: {
        handleManagerRetryRecharge(_id, equity_log_id) {
            this.$confirm('是否重试?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$service.OnlineOrderService
                    .handleManagerRetryRecharge({ _id, equity_log_id })
                    .then(res => {
                        if (res.data.code === 0) {
                            this.getMembers();
                            this.$message({
                                message: '成功！',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: 'error'
                            });
                        }
                    }).catch((err) => {
                        window.console.log(err)
                        this.$message({
                            message: '网络错误，请重试！',
                            type: 'error'
                        });
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        queryList() {
            if (this.create_date.length >= 2 && this.create_date[0] && this.create_date[1]) {
                this.paginationInfo.condition.create_date = [this.create_date[0], this.create_date[1]];
            } else {
                this.paginationInfo.condition.create_date = null;
            }
            if (this.create_date1.length >= 2 && this.create_date1[0] && this.create_date1[1]) {
                this.paginationInfo.condition.log_create_date = [this.create_date1[0], this.create_date1[1]];
            } else {
                this.paginationInfo.condition.log_create_date = null;
            }
            this.paginationInfo.pageNo = 1;
            this.getMembers()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getMembers();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getMembers();
        },
        getMembers() {
            this.$service.OnlineOrderService
                .getMembers(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data
                        this.paginationInfo.total = dataInfo.total;
                        this.tableData = dataInfo.equity_members;
                    }
                })
        },
        judgeMemberName(str, cstr) {
            let name = '无匹配数据！';
            this.EquityMemberList.forEach((item) => {
                if (item.dictdata_name == str) {
                    if (cstr) {
                        const subMemberTypes = cstr.split(',')
                        subMemberTypes.forEach(s => {
                            let mappingName = ''
                            if(s && item.dictdata_object[s]) {
                                mappingName += item.dictdata_object[s].subject;
                            }
                            name = mappingName
                        })
                    } else {
                        name = item.dictdata_object.default.subject;
                    }
                }
            })
            return name
        },
        getEquityMember() {
            this.$service.OnlineOrderService
                .getEquityMember({ dict: 'EquityMember' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.EquityMemberList = res.data.data && res.data.data.dict_data ? res.data.data.dict_data : [];
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
<style lang='less' scoped>
.members {}
</style>