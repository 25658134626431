<template lang="pug">
  .event-track
    el-row
      el-col(style="margin-bottom: 1rem;")
        el-input(type="text", v-model="pageModel.condition.ad_no", placeholder="请输入ad_no", style="width:200px;", clearable)
        el-input(type="text", v-model="pageModel.condition.ad_channel", placeholder="请输入ad_channel", style="width:200px;", clearable)
        el-date-picker(v-model="pageModel.condition.create_date[0]", align="right", type="date", placeholder="选择开始时间",
          :picker-options="{disabledDate(time) {return time.getTime() > Date.now();}}")
        el-date-picker(v-model="pageModel.condition.create_date[1]", align="right", type="date", placeholder="选择结束时间",
          :picker-options="{disabledDate(time) {return time.getTime() > Date.now();}}")
        </el-date-picker>
        el-select(placeholder="事件类型", v-model="pageModel.condition.event_type", @change="query(1)")
          el-option(label="全部" value="")
          el-option(label="FORM" value="FORM")
          el-option(label="CLICK" value="CLICK")
          el-option(label="ENTER_PHONE" value="ENTER_PHONE")
        el-button(type="primary", @click="query(1)") 查询
    el-table(:data="eventTracks", stripe, v-loading="dataLoading", @sort-change="handleSort")
      el-table-column(type="expand")
        template(slot-scope="scope")
          ul.expand-list
            li
              el-tag Page URL:
              | {{scope.row.page_url || '-'}}
            li
              el-tag Sub Channel:
              | {{scope.row.sub_channel || '-'}}
            li
              el-tag Landing Page:
              | {{scope.row.landing_page || '-'}}
            li
              el-tag IP:
              | {{scope.row.ip || '-'}}
      el-table-column(label="create_date")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="update_date")
        template(slot-scope="scope")
          span {{$moment(scope.row.update_date).calendar()}}
      el-table-column(prop="event_type", label="event_type")
      el-table-column(prop="ad_no", label="ad_no")
      el-table-column(prop="event_val", label="event_val", sortable="custom")
        template(slot-scope="scope")
          span {{scope.row.event_val || '-'}}
      el-table-column(label="event_prop")
        template(slot-scope="scope")
          span {{scope.row.event_prop || '-'}}
      el-table-column(label="device")
        template(slot-scope="scope")
          span {{scope.row.device || '-'}}
      el-table-column(prop="location", label="location")
      el-table-column(prop="goods_prop", label="goods_prop")
      el-table-column(label="click_id")
        template(slot-scope="scope")
          span(v-if="!scope.row.click_id") -
          el-tooltip(v-else, class="item", effect="dark", :content="scope.row.click_id || '-'", placement="left")
            span.click-id {{scope.row.click_id}}
      el-table-column(prop="mobile", label="mobile")
      el-table-column(prop="ad_channel", label="ad_channel")
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        dataLoading: false,
        eventTracks: [],
        pageModel: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
          condition: {
            event_type: '',
            ad_no: '',
            ad_channel: '',
            create_date: [new Date(), new Date()]
          },
          sort: null
        }
      }
    },
    mounted() {
      this.query(1)
    },
    methods: {
      handleSort(sort) {
        this.pageModel.sort = {}
        this.pageModel.sort[sort.prop] = sort.order
        this.query(1)
      },
      query(pageNo) {
        this.pageModel.pageNo = pageNo || this.pageModel.pageNo
        this.dataLoading = true
        this.$service.EventTrackService
          .query({
            pageNo: this.pageModel.pageNo,
            pageSize: this.pageModel.pageSize,
            condition: this.pageModel.condition,
            sort: this.pageModel.sort
          })
          .then(res => {
            if (res.status === 201) {
              this.pageModel.total = res.data.data.total
              this.eventTracks = res.data.data.event_tracks
            }
          })
          .finally(() => {
            this.dataLoading = false
          })
      }
    }
  }
  </script>
  
  <style lang="less" scope>
  </style>