<template lang="pug">
  .paidgoods
    el-row
      el-col(:span="12")
        el-form(:inline="true")
          el-form-item(label="上架状态")
            el-select(v-model="pageModel.condition.status", @change="query(1)")
              el-option(label="全部", value="all")
              el-option(label="已上架", value="1")
              el-option(label="已下架", value="0")
          el-form-item(label="渠道")
            el-select(v-model="pageModel.condition.channel", @change="query(1)")
              el-option(label="全部", value="all")
              el-option(label="微信小程序", value="WX_MINIAPP")
              el-option(label="H5收单", value="EASY_ORDER")
      el-col(:span="12")
        el-button(type="primary", @click="toForm") 添加商品
    el-table(:data="paidgoods", stripe)
      el-table-column(label="排序")
        template(slot-scope="scope")
          div(v-if="modifySortGood === scope.row._id")
            el-input-number(v-model="scope.row.sort", label="数值越大越靠前", size="mini", controls-position="right")
            el-button(type="primary", size="mini", @click="handleSaveSort(scope.row)", round) 保存
          div(v-else)
            span {{scope.row.sort}}
            i.link.el-icon-edit(@click="modifySortGood = scope.row._id")
      el-table-column(label="更新日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.update_date).calendar()}}
      el-table-column(label="渠道")
        template(slot-scope="scope")
          span {{channelLabels[scope.row.channel]}}
      el-table-column(label="封面图")
        template(slot-scope="scope")
          el-image.cover-image(
            :src="scope.row.__cover_images[0]",
            fit="cover",
            :preview-src-list="scope.row.__cover_images"
          )
      el-table-column(prop="name", label="商品")
      el-table-column(label="规格", width="200")
        template(slot-scope="scope")
          div(v-for="item in scope.row.sku", :key="item._id")
            el-tooltip(:content="`规格：${item.name}，原价：${item.price}，销售价：${item.sale_price}，${item.status === 0 ? '已下架' : '已上架'}`", placement="top")
              el-tag(:type="item.status === 0 ? 'info' : 'success' ") {{item.name}}
      el-table-column(label="销量")
        template(slot-scope="scope")
          span {{scope.row.sales_count}}
          //- i.link.el-icon-refresh(@click="handleSalesSync(scope.row)")
      el-table-column(label="虚拟销量")
        template(slot-scope="scope")
          span {{scope.row.v_sales_count}}
          i.link.el-icon-edit(@click="handleVisualSalesModify(scope.row)")
      el-table-column(label="状态操作")
        template(slot-scope="scope")
          el-popconfirm(v-if="scope.row.status === 1", title="确定下架？", @confirm="updateStatus(scope.row, 0)")
            el-button(slot="reference", type="primary", size="mini") 下架
          el-popconfirm(v-else, title="确定上架？", @confirm="updateStatus(scope.row, 1)")
            el-button(slot="reference", type="warning", size="mini") 上架
      el-table-column(label="状态操作")
        template(slot-scope="scope")
          el-button(type="warning", @click="toForm(scope.row)") 修改
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>

export default {
  data() {
    return {
      paidgoods: [],
      modifyGood: {},
      modifySortGood: '',
      channelLabels: {
        WX_MINIAPP: '微信小程序',
        EASY_ORDER: 'H5收单'
      },
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {
          status: 'all',
          channel: 'all'
        }
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      let _condition = {}
      if(this.pageModel.condition.status !== 'all') {
        _condition.status = this.pageModel.condition.status
      }
      if(this.pageModel.condition.channel !== 'all') {
        _condition.channel = this.pageModel.condition.channel
      }
      this.$service.PaidGoodService
        .query({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          condition: _condition
        })
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            res.data.data.paidgoods.forEach(item => {
              item.__cover_images = []
              item.cover_images.forEach(img => {
                item.__cover_images.push(`https://tlhw.oss-cn-shanghai.aliyuncs.com/${img}!414_jpeg_zoom`)
              })
            })
            this.paidgoods = res.data.data.paidgoods
          }
        })
    },
    handleGroupModify(good) {
      this.modifyGood = good
      this.$refs.groupSelector.show()
    },
    handleCoinModify(good) {
      this.modifyGood = good
      this.$refs.coinForm.show()
    },
    handleVisualSalesModify(good) {
      this.modifyGood = good
      this.$refs.visualSalesForm.show()
    },
    handleSalesSync(good) {
      this.$service.GoodService
        .syncSales(good._id)
        .then(() => {
          this.$message.success('销量已同步')
          this.query()
        })
    },
    handleSaveSort(good) {
      this.modifySortGood = ''
      this.$service.GoodService
        .updateSort(good._id, good.sort)
        .then(() => {
          this.query()
        })
    },
    handleUpdateCanBargain(good, canBargain) {
      this.$service.GoodService
        .updateCanBargain(good._id, canBargain)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '已更新商品砍价状态',
              type: 'success'
            })
            this.query()
          }
        })
    },
    toForm(paidgood) {
      this.$router.push({
        name: 'paidgoodForm',
        query: paidgood ? { id: paidgood._id } : undefined
      })
    },
    updateStatus(paidgood, status) {
      this.$service.PaidGoodService
        .updateStatus(paidgood._id, status)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '已更新商品上架状态',
              type: 'success'
            })
            this.query()
          }
        })
    }
  }
}
</script>

<style lang="less" scope>
.cover-image{
  width: 60px;
  height: 40px;
}
.link{
  cursor: pointer;
}
</style>