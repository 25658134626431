<template lang="pug">
.dictlist
  el-row
    el-col(:span="12")
    el-col(:span="12")
      //- el-button(type="primary", @click="toForm") 添加字典
  el-table(:data="dicts", stripe)
    el-table-column(type="expand")
      template(slot-scope="scope")
        ul.expand-list
          li
            h3 字典数据
          li(v-for="(item,index) in scope.row.dict_data" :key="index")
            el-tag {{item.dictdata_name || '-'}} | {{item.dictdata_value || '-'}} | {{item.dictdata_object || '-'}}
    el-table-column(prop="dict_name", label="字典名")
    el-table-column(prop="dict_value", label="查询关键字")
    el-table-column(prop="dict_data.length", label="字典内容数量")
    el-table-column(label="操作")
      template(slot-scope="scope")
        //- el-button(type="primary", size="mini", @click="toForm(scope.row)") 编辑
  el-pagination(
    background,
    :current-page="pageModel.pageNo",
    layout="prev, pager, next, total",
    :total="pageModel.total"
    :page-size="pageModel.pageSize"
    @current-change="query"
  )
</template>

<script>

export default {
  data() {
    return {
      dicts: [],
      modifyGood: {},
      modifySortGood: '',
      pageModel: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
        condition: {
          status: 'all'
        }
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      let _condition = {}
      this.$service.CommonService
        .queryDicts({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          condition: _condition
        })
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.dicts = res.data.data.dicts
          }
        })
    },
    handleUpdateStatus(good, status) {
      this.$service.RechargeCenterService
        .updateStatus(good._id, status)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '已更新上架状态',
              type: 'success'
            })
            this.query()
          }
        })
    },
    toForm(dict) {
      this.$router.push({name: 'dictForm', query: {id: dict ? dict._id : undefined }})
    }
  }
}
</script>

<style lang="less" scope>
.cover-image{
  width: 60px;
  height: 40px;
}
.link{
  cursor: pointer;
}
</style>